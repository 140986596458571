import Type from "../enums/Type";
import {PRODUCTS} from "../urls/frontendUrl";

export const UserRole = {
  Admin: 0,
  Editor: 1,
};

/*
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  {
    id: 'en',
    icon: 'en',
    locale: 'en',
    name: 'English',
    direction: 'ltr',
    languageId: 'english'
  },
  {
    id: 'fr',
    icon: 'fr',
    locale: 'fr',
    name: 'French',
    direction: 'ltr',
    languageId: 'french',
  },
];

export const firebaseConfig = {
  apiKey: 'AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg',
  authDomain: 'gogo-react-login.firebaseapp.com',
  databaseURL: 'https://gogo-react-login.firebaseio.com',
  projectId: 'gogo-react-login',
  storageBucket: 'gogo-react-login.appspot.com',
  messagingSenderId: '216495999563',
};

export const adminRoot = '/';
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${adminRoot}/#`;
export const servicePath = 'https://api.coloredstrategies.com';

export const currentUser = {
  id: 1,
  title: 'Sarah Kortney',
  img: '/assets/img/profiles/l-1.jpg',
  date: 'Last seen today 15:24',
  role: UserRole.Admin,
};

export const urlsParams = {
    [PRODUCTS.SELF.LIST]: {
      categories: {
          label: 'tags',
          clear: 'c-tags'
      },
      grid: {
        label: 'grid',
        values: [Type.LIST, Type.TWO_COLS, Type.THREE_COLS, Type.FOUR_COLS],
        className: {
          [Type.TWO_COLS]: "col-6",
          [Type.THREE_COLS]: "col-6 col-md-4 col-lg-4",
          [Type.FOUR_COLS]: "col-6 col-md-4 col-lg-4 col-xl-3"
        },
        unitsPerGrid: {
          [Type.LIST]: 6,
          [Type.TWO_COLS]: 6,
          [Type.THREE_COLS]: 9,
          [Type.FOUR_COLS]: 12,
        }
      }
    }
};
export const defaultCurrency = {
  id: 1,
  rate: 1,
  code: "CAD",
  symbol: "CA$",
  name: "Canadian dollar",
  position: Type.BEGINNING,
};

export const freeShippingCountries = process.env.REACT_APP_ENABLE_FREE_SHIPPING !== "true" ? [] : [
    'CAN',
    'USA'
];

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.purplemonster';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const minPasswordLength = 3;
export const NUMBER_OF_CATEGORIES_HEADER = 10;

const backendBaseUrl = (function (mode) {
  switch (mode) {
    case 'LOCAL':
      return process.env.REACT_APP_API_URL_LOCAL;
    case 'PROD':
      return process.env.REACT_APP_API_URL_PROD;
    default:
      return process.env.REACT_APP_API_URL_DEV;
  }
})(process.env.REACT_APP_BACKEND_MODE);

export const backendUrl = {
  baseUrl : backendBaseUrl,
  timeout: 60000,
  oauth: {
    clientId: 'buyamshop-app',
    clientSecret: 'buyamshop.secret',
    grantType: 'password',
  },
};

export const colors = [
  'bluenavy',
  'blueyale',
  'blueolympic',
  'greenmoss',
  'greenlime',
  'purplemonster',
  'orangecarrot',
  'redruby',
  'yellowgranola',
  'greysteel',
];
