/**
 * Products Reducers
 */
import {
    PRODUCTS,
    PRODUCTS_SUCCESS,
    PRODUCTS_FAILURE,
    PRODUCTS_UPDATE_ONE
} from '../actions/action-types';
import Product from "../models/Product";

/**
 * initial state
 */
const INIT_STATE = {
    currentPage: null,
    data: null,
    totalPages: null,
    totalItems: null,
    error: null,
    loading: false,
    fetched: false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case PRODUCTS:
            return { ...state, loading: true };

        case PRODUCTS_SUCCESS:
            return {
                ...state,
                fetched: true,
                loading: false,
                ...action.payload,
                data: action.payload.data.map(product => new Product(product))
            };

        case PRODUCTS_FAILURE:
            return { ...state, fetched: true, loading: false, error: action.payload };

        case PRODUCTS_UPDATE_ONE:
            if (!Array.isArray(state.data)) {
                return state;
            }

            const newProduct = action.payload;
            const products = [...state.data];
            const index = products.findIndex(p => p.id === newProduct.id);
            if (index !== -1 ) {
                products[index] = newProduct instanceof Product ? newProduct : new Product(newProduct);
                return {
                    ...state,
                    data: products
                };
            }

            return state;

        default: return { ...state };
    }
}
