import { scrollTop } from '../utils';
import React, { useEffect } from 'react';
import CanRoute from "../components/CanRoute";
import {Redirect, Route, Switch} from 'react-router-dom';
import LoadingOverlay from '../components/features/loading-overlay';
import {ACCOUNT, AUTH, CATEGORIES, OTHERS, PRODUCTS, ROOT, SHOP} from "../urls/frontendUrl";

const ShopPages = React.lazy(() => import('./shop-route.js'));
const ProductsPages = React.lazy(() => import('./products.js'));
const HomePage = React.lazy(() => import('../components/home'));
const ErrorPage = React.lazy(() => import('../components/pages/others/404'));
const CategoriesPages = React.lazy(() => import('../components/pages/categories'));
const AccountPages = React.lazy(() => import('../components/pages/account/index.js'));
const ShopPolicyPage = React.lazy(() => import('../components/pages/others/shop-policy'));
const AboutUsPage = React.lazy(() => import('../components/pages/others/about-1'));
const ContactPage = React.lazy(() => import('../components/pages/others/contact-1'));
const ReviewsPage = React.lazy(() => import('../components/pages/others/Reviews'));
const PrivacyPolicy = React.lazy(() => import('../components/pages/others/privacy-policy'));
const Disclaimer = React.lazy(() => import('../components/pages/others/disclaimer'));
const ReturnPolicy = React.lazy(() => import('../components/pages/others/return-policy'));
const TermAndService = React.lazy(() => import('../components/pages/others/term-service'));
const AuthPage = React.lazy(() => import('../components/pages/others/auth'));
const ForgotPassword = React.lazy(() => import('../components/pages/others/forgotPassword'));
const ResetPassword = React.lazy(() => import('../components/pages/others/resetPassword'));

export default function AppRoot() {
    useEffect(() => {
        scrollTop();

        // Remove the overlay set into the index.html file
        document.querySelector('body').classList.add("loaded");
        document.querySelector("#root").classList.add( "loaded");
    }, []);

    return (
        <React.Suspense fallback={<LoadingOverlay /> }>
            <Switch>
                <Route exact path={OTHERS.ABOUT} component={AboutUsPage} />
                <Route exact path={CATEGORIES.LIST} component={CategoriesPages} />
                <Route exact path={OTHERS.NOT_FOUND} component={ErrorPage} />

                <CanRoute path={ACCOUNT.SELF} component={AccountPages} />
                <Route path={OTHERS.AUTH} component={AuthPage} />
                <Route path={OTHERS.CONTACT} component={ContactPage} />
                <Route path={AUTH.FORGOT_PASSWORD} component={ForgotPassword} />
                <Route path={AUTH.RESET_PASSWORD} component={ResetPassword} />
                <Route path={OTHERS.REVIEWS} component={ReviewsPage} />
                <Route path={PRODUCTS.BASE} component={ProductsPages} />
                <Route path={SHOP.SELF} component={ShopPages} />
                <Route path path={OTHERS.SHOP_POLICIES} component={ShopPolicyPage} />
                <Route path path={OTHERS.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route path path={OTHERS.DISCLAIMER} component={Disclaimer} />
                <Route path path={OTHERS.RETURN_POLICIES} component={ReturnPolicy} />
                <Route path path={OTHERS.TERM_AND_SERVICE} component={TermAndService} />

                <Route exact path={ROOT} component={HomePage} />
                <Redirect to={OTHERS.NOT_FOUND} />
            </Switch>
        </React.Suspense>
    )
}
