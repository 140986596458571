import messages from '../locales/fr_FR';

const saLang = {
    id: 'fr',
    messages,
    icon: 'fr',
    locale: 'fr',
    name: 'French',
    languageId: 'french',
};
export default saLang;
