import React from 'react';

/**
 * Bar loader
 */
const BarLoader = ({ wrapClassNames = "", mainClassNames = "" }) => {
    return (
        <div className={`${wrapClassNames}`}>
            <div className={`custom-bar-loader ${mainClassNames}`}>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
};

export default BarLoader;
