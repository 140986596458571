import {
    AUTH_DATA,
    AUTH_DATA_FAILURE,
    AUTH_DATA_SUCCESS,
} from "./action-types";
import {formatMessage} from "../lang";
import {AUTH} from "../urls/backendUrl";
import StorageHelper from "../helpers/StorageHelper";
import {NotificationManager} from "react-notifications";
import * as Constants from "../constants/defaultValues";
import {removeAuthToken, saveAuthToken} from "../helpers/userHelper";
import {getFullAuthorisationRequestConfig, makeRequest} from "../helpers/funcHelper";

const errorLoginDisplay = (error) => {
    if (error && (error.message.includes('code 400') || error.message.includes('code 401'))) {
        NotificationManager.error(formatMessage("request.error.auth.login.failed"));
        return;
    }

    NotificationManager.error(error.message);
};

export const loginUser = ({email, password}) => {
    const config = getFullAuthorisationRequestConfig();
    const _data = {};
    _data.username = email;
    _data.password = password;
    _data.grantType = Constants.backendUrl.oauth.grantType;
    _data.clientId = Constants.backendUrl.oauth.clientId;
    _data.clientSecret = Constants.backendUrl.oauth.clientSecret;

    delete _data.login;

    return new Promise((resolve, reject) => {
        makeRequest('post', AUTH.LOGIN, _data, config)
            .then(tokens => {
                saveAuthToken(tokens.accessToken, tokens.tokenType, tokens.expiresIn, tokens.refreshToken);
                resolve(tokens);
            })
            .catch((error) => {
                errorLoginDisplay(error);
                reject(error);
            });
    });
};

export const logoutUser = () => {
    removeAuthToken();
};

export const registerUser = (data) => {
    return new Promise((resolve, reject) => {
        makeRequest('post', AUTH.REGISTER, data)
            .then(tokens => {
                saveAuthToken(tokens.accessToken, tokens.tokenType, tokens.expiresIn, tokens.refreshToken);
                resolve(tokens);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export const setAuthUser = () => dispatch => {
    return new Promise((resolve, reject) => {
        dispatch({ type: AUTH_DATA });
        const visitorId = StorageHelper.getItem(StorageHelper.VISITOR_ID);
        const data = {};
        if (visitorId) {
            data.visitor = visitorId;
        }
        makeRequest('get', AUTH.ME, data, {skipError: true})
            .then(user => {
                StorageHelper.removeItem(StorageHelper.VISITOR_ID);
                StorageHelper.setItem(StorageHelper.USER_ID, user.id);
                dispatch({
                    type: AUTH_DATA_SUCCESS,
                    payload: user
                });
                resolve(user);
            })
            .catch((error) => {
                dispatch({
                    type: AUTH_DATA_FAILURE,
                    payload: null
                });
                reject(error);
            });
    });
};

export const setStoreAuthUser = (user) => dispatch => {
    dispatch({
        type: AUTH_DATA_SUCCESS,
        payload: user
    });
};
