import {
    SF__SORT_FIELD,
    SF__GRID_SYSTEM,
    SF__QUERY_CHANGED,
    SF__SORT_DIRECTION,
    SF_PAGINATE_CHANGE,
    SF__FILTER_PRICE_RANGE,
    SF__FILTER_SIZE_TOGGLE,
    SF__FILTER_COLOR_TOGGLE,
    SF__FILTER_CATEGORY_ADDED,
    SF__FILTER_CATEGORY_TOGGLE,
    SF__FILTER_CATEGORY_CLEARED,
} from '../actions/action-types';
import Type from "../enums/Type";
import {PRODUCTS} from "../urls/frontendUrl";
import {urlsParams} from "../constants/defaultValues";
import SortDirectionType from "../enums/SortDirectionType";

const REACT_APP_MAX_PRICE_RANGE = 100000;

/**
 * initial state
 */
const INIT_STATE = {
    query: '',
    filters: {
        categories: [],
        colors: [],
        sizes: [],
        priceRange: {
            min: 0,
            max: REACT_APP_MAX_PRICE_RANGE,
        },
    },
    grid: Type.FOUR_COLS,
    sort: {
        field: SortDirectionType.CREATION_DATE,
        direction: SortDirectionType.DESC,
    },
    pagination: {
        page: 1,
        size: urlsParams[PRODUCTS.SELF.LIST].grid.unitsPerGrid[Type.FOUR_COLS]
    },
    shouldReload: true,
};

export default (state = INIT_STATE, action) => {
    const newState = {...state, shouldReload: true};
    switch (action.type) {
        case SF__QUERY_CHANGED:
            return { ...state, query: action.payload };

        case SF__GRID_SYSTEM:
            if (urlsParams[PRODUCTS.SELF.LIST].grid.values.includes(action.payload)) {
                newState.grid = action.payload;
                newState.pagination.size = urlsParams[PRODUCTS.SELF.LIST].grid.unitsPerGrid[action.payload];
                return { ...state, shouldReload: false, grid: action.payload };
            }

            return state;

        case SF__FILTER_CATEGORY_CLEARED:
            newState.filters.categories = [];
            return newState;

        case SF__FILTER_CATEGORY_ADDED:
            if (action.payload.clear) {
                newState.filters.categories = [];
            }
            action.payload.ids.forEach(catId => {
                if (!state.filters.categories.includes(catId)) {
                    newState.filters.categories.push(catId);
                }
            });
            return newState;

        case SF__FILTER_CATEGORY_TOGGLE:
            if (!state.filters.categories.includes(action.payload)) {
                newState.filters.categories.push(action.payload);
            } else {
                newState.filters.categories = newState.filters.categories.filter( item => item !== action.payload);
            }
            return newState;

        case SF__FILTER_COLOR_TOGGLE:
            if (!state.filters.colors.includes(action.payload)) {
                newState.filters.colors.push(action.payload);
            } else {
                newState.filters.colors = newState.filters.colors.filter( item => item !== action.payload);
            }
            return newState;

        case SF__FILTER_SIZE_TOGGLE:
            if (!state.filters.sizes.includes(action.payload)) {
                newState.filters.sizes.push(action.payload);
            } else {
                newState.filters.sizes = newState.filters.sizes.filter( item => item !== action.payload);
            }
            return newState;

        case SF__FILTER_PRICE_RANGE:
            newState.filters.priceRange.min = action.payload.min;
            newState.filters.priceRange.max = action.payload.max;
            return newState;

        case SF_PAGINATE_CHANGE:
            newState.pagination.page = action.payload;
            return newState;

        case SF__SORT_FIELD:
            newState.sort.field = action.payload;
            return newState;

        case SF__SORT_DIRECTION:
            newState.sort.direction = action.payload;
            return newState;
        default:
            return state;
    }
}
