import BaseEnum from "../enums/BaseEnum";

const PREFIX = "buyamshop__";

export default class StorageHelper extends BaseEnum {
    static PAYMENT_METHOD = 'PAYMENT_METHOD';
    static LOCALE = 'LOCALE';
    static VISITOR_ID = 'VISITOR_ID';
    static USER_ID = 'USER_ID';
    static COUNTRY_ALPHA_2_CODE = 'COUNTRY_ALPHA_2_CODE';
    static COUNTRY_ALPHA_3_CODE = 'COUNTRY_ALPHA_3_CODE';
    static CART_ID = 'CART_ID';
    static COUNTRY_REGION_NAME = 'COUNTRY_REGION_NAME';
    static CURRENCY_OBJECT = 'CURRENCY_OBJECT';

    static getItem = (key) => {
        const item = localStorage.getItem(`${PREFIX}${key}`);
        return item ? item : null;
    };

    /**
     * Set item into local storage
     * @param key {String}
     * @param value {String}
     */
    static setItem = (key, value) => {
        localStorage.setItem(`${PREFIX}${key}`, value);
    };

    static removeItem = key => {
        localStorage.removeItem(`${PREFIX}${key}`);
    };
}
