import {connect} from "react-redux";
import React, {Component} from 'react';
import {setRequestGlobalAction} from "../actions";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";

/**
 * Display a global loader to the page
 */
class RequestGlobalLoader extends Component {
    state = {
        loading: this.props.loading,
    };

    // Update the loading props and toggle loader accordingly
    static getDerivedStateFromProps(props, state) {
        if (props.loading !== state.loading) {
            props.setRequestGlobalAction(props.loading);

            return {
                loading: props.loading
            }
        }
        return null;
    }

    render() {
        return (
            <div className={`global-loader justify-content-center`}>
                {this.props.requestGlobalLoader && <LinearProgress />}
                <div className={`${this.props.requestGlobalLoader ? 'global-loader-overlay' : ''}`} />
            </div>
        );
    }
}

const mapStateToProps = ({requestGlobalLoader, authUser}) => ({
    requestGlobalLoader,
    // loading: authUser.loading
    loading: false
});

export default connect(mapStateToProps, {setRequestGlobalAction})(RequestGlobalLoader);
