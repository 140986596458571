import {
    CURRENCIES,
    CURRENCIES_SUCCESS,
    CURRENCIES_FAILURE,
} from '../actions/action-types';

/**
 * initial state
 */
const INIT_STATE = {
    data: null,
    error: null,
    loading: false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case CURRENCIES:
            return { ...state, loading: true };

        case CURRENCIES_SUCCESS:
            return { ...state, loading: false, data: action.payload };

        case CURRENCIES_FAILURE:
            return { ...state, loading: false, error: action.payload };

        default: return { ...state };
    }
}
