import React from "react";
import {getImageUrl} from "../helpers/funcHelper";
import PaymentMethod from "../enums/PaymentMethod";

export default [
    {
        name: 'Credit Card',
        type: PaymentMethod.STRIPE,
        fees: Number(process.env.REACT_APP_STRIPE_FEES),
        description: (
            <div>
                <img src={getImageUrl("payments-summary.png")} alt="payments cards" />
            </div>
        ),
    },
    {
        name: 'Paypal',
        type: PaymentMethod.PAYPAL,
        fees: Number(process.env.REACT_APP_PAYPAL_FEES),
        description: (
            <div>
                <img src={getImageUrl("payments-summary.png")} alt="payments cards" />
            </div>
        ),
    },
]
