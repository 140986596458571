import React from "react";
import {connect} from "react-redux";
import {OTHERS} from "../urls/frontendUrl";
import {Route, Redirect} from "react-router-dom";

/**
 * Protected route
 * @param authUser
 * @param can
 * @param Component
 */
const CanRoute = ({ authUser, component: Component, ...restProps }) => {
    return authUser
        ? <Route {...restProps} component={Component} />
        : <Redirect to={OTHERS.NOT_FOUND} />;
};

export default connect(({ authUser }) => ({authUser: authUser.data}), {})(CanRoute);
