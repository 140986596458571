import {
	SETTINGS,
	SET_LANGUAGE,
	SET_CURRENCY,
	SETTINGS_FAILURE,
	SETTINGS_SUCCESS,
} from "../actions/action-types";
import StorageHelper from "../helpers/StorageHelper";
import {getCurrencyOrDefaultOne, getDefaultLanguage} from "../helpers/funcHelper";

/**
 * initial app settings
 */
const INIT_STATE = {
	data: {
		data: null,
		error: null,
		loading: false,
		fetched: false,
	},
	isProd: true,
	language: getDefaultLanguage(),
	currency: getCurrencyOrDefaultOne(),
	// languages: AppConfig.languages,
};

export default (state = INIT_STATE, action) => {
	switch (action.type) {
		// set language
		case SET_LANGUAGE:
			StorageHelper.setItem(StorageHelper.LOCALE, action.payload.locale);
			return { ...state, language: action.payload };

		case SET_CURRENCY:
			StorageHelper.setItem(StorageHelper.CURRENCY_OBJECT, JSON.stringify(action.payload));
			return { ...state, currency: action.payload };

		case SETTINGS:
			return { ...state, data: {loading: true} };

		case SETTINGS_SUCCESS:
			return { ...state, data: {fetched: true, loading: false, data: action.payload }};

		case SETTINGS_FAILURE:
			return { ...state, data: {fetched: true, loading: false, error: action.payload }};

		default: return { ...state };
	}
}
