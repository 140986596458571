export default {
    OTHERS: {
        USER_EMAIL_ALREADY_USED: {
            NAME: 'USER_EMAIL_ALREADY_USED',
            MESSAGE: "errors.400.USER_EMAIL_ALREADY_USED",
            DESCRIPTION: `"The email " + email + " is already used!"`
        },
        USER_WITH_TOKEN_NOT_FOUND: {
            NAME: 'USER_WITH_TOKEN_NOT_FOUND',
            MESSAGE: "errors.400.USER_WITH_TOKEN_NOT_FOUND",
            DESCRIPTION: `"The user with token " + token + " was not found !"`
        },
        USER_WITH_EMAIL_NOT_FOUND: {
            NAME: 'USER_WITH_EMAIL_NOT_FOUND',
            MESSAGE: "errors.400.USER_WITH_EMAIL_NOT_FOUND",
            DESCRIPTION: `"The user with email " + email + " was not found !"`
        },
        USER_IDENTITY_NOT_PROOF: {
            NAME: 'USER_IDENTITY_NOT_PROOF',
            MESSAGE: "errors.400.USER_IDENTITY_NOT_PROOF",
            DESCRIPTION: `"No user connected !"`
        },
        ADDRESS_NOT_FOUND: {
            NAME: 'ADDRESS_NOT_FOUND',
            MESSAGE: "errors.400.ADDRESS_NOT_FOUND",
            DESCRIPTION: `"The billing address with id " + id + " was not found !"`
        },
        PROVINCE_NOT_FOUND: {
            NAME: 'PROVINCE_NOT_FOUND',
            MESSAGE: "errors.400.PROVINCE_NOT_FOUND",
            DESCRIPTION: `"The province with id " + province_id + " was not found !"`
        },
        COUNTRY_NOT_FOUND: {
            NAME: 'COUNTRY_NOT_FOUND',
            MESSAGE: "errors.400.COUNTRY_NOT_FOUND",
            DESCRIPTION: `"The country with id " + country_id + "`
        },
        PROVINCE_NAME_ALREADY_EXIST: {
            NAME: 'PROVINCE_NAME_ALREADY_EXIST',
            MESSAGE: "errors.400.PROVINCE_NAME_ALREADY_EXIST",
            DESCRIPTION: `"The province with name " + name + " already exist !"`
        },
        BAD_CATEGORY_PARENT: {
            NAME: 'BAD_CATEGORY_PARENT',
            MESSAGE: "errors.400.BAD_CATEGORY_PARENT",
            DESCRIPTION: `"A parent category  cannot be a parent of another category !"`
        },
        CATEGORY_NAME_ALREADY_EXIST: {
            NAME: 'CATEGORY_NAME_ALREADY_EXIST',
            MESSAGE: "errors.400.CATEGORY_NAME_ALREADY_EXIST",
            DESCRIPTION: `"The category with name " + name + " already exist !"`
        },
        BAD_CATEGORY_PARENT_OWNER: {
            NAME: 'BAD_CATEGORY_PARENT_OWNER',
            MESSAGE: "errors.400.BAD_CATEGORY_PARENT_OWNER",
            DESCRIPTION: `"The category cannot be his own parent !"`
        },
        CATEGORY_NOT_FOUND: {
            NAME: 'CATEGORY_NOT_FOUND',
            MESSAGE: "errors.400.CATEGORY_NOT_FOUND",
            DESCRIPTION: `"The category with id " + id + " was not found !"`
        },
        CATEGORY_PARENT_NOT_FOUND: {
            NAME: 'CATEGORY_PARENT_NOT_FOUND',
            MESSAGE: "errors.400.CATEGORY_PARENT_NOT_FOUND",
            DESCRIPTION: `"The parent category with id " + parent_id + " doesn't exist !"`
        },
        PAGE_OR_SIZE_LESS_THAN_ZERO: {
            NAME: 'PAGE_OR_SIZE_LESS_THAN_ZERO',
            MESSAGE: "errors.400.PAGE_OR_SIZE_LESS_THAN_ZERO",
            DESCRIPTION: `"The page and size for pagination have to be greater than zero"`
        },
        NULL_USER_AND_VISITOR: {
            NAME: 'NULL_USER_AND_VISITOR',
            MESSAGE: "errors.400.NULL_USER_AND_VISITOR",
            DESCRIPTION: `"You must specified user id or visitor !"`
        },
        VISITOR_CART_NOT_FOUND: {
            NAME: 'VISITOR_CART_NOT_FOUND',
            MESSAGE: "errors.400.VISITOR_CART_NOT_FOUND",
            DESCRIPTION: `"The cart was not found for visitor!"`
        },
        CART_NOT_FOUND: {
            NAME: 'CART_NOT_FOUND',
            MESSAGE: "errors.400.CART_NOT_FOUND",
            DESCRIPTION: `"The cart was not found!"`
        },
        COUPON_NOT_FOUND: {
            NAME: 'COUPON_NOT_FOUND',
            MESSAGE: "errors.400.COUPON_NOT_FOUND",
            DESCRIPTION: `"The coupon with code " + voucher_code + " was not found "`
        },
        PRODUCT_NOT_FOUND: {
            NAME: 'PRODUCT_NOT_FOUND',
            MESSAGE: "errors.400.PRODUCT_NOT_FOUND",
            DESCRIPTION: `"The product with id " + id + " was not found "`
        },
        MAX_NBER_IMAGE_REACHED: {
            NAME: 'MAX_NBER_IMAGE_REACHED',
            MESSAGE: "errors.400.MAX_NBER_IMAGE_REACHED",
            DESCRIPTION: `"Product variant must have at most ten image "`
        },
        PRODUCT_VARIANT_NOT_FOUND: {
            NAME: 'PRODUCT_VARIANT_NOT_FOUND',
            MESSAGE: "errors.400.PRODUCT_VARIANT_NOT_FOUND",
            DESCRIPTION: `"The product variant with id " + product_variant_id + " was not found"`
        },
        QUANTITY_BIGGER_THAN_AVAILABLE: {
            NAME: 'PRODUCT_VARIANT_NOT_FOUND',
            MESSAGE: "errors.400.PRODUCT_VARIANT_NOT_FOUND",
            DESCRIPTION: `"The quantity of the product variant with id "+ product_variant_id + " enter  is bigger than the quantity in stock!"`
        },
        PRODUCT_VARIANT_ALREADY_EXIST: {
            NAME: 'PRODUCT_VARIANT_ALREADY_EXIST',
            MESSAGE: "errors.400.PRODUCT_VARIANT_ALREADY_EXIST",
            DESCRIPTION: `"Product variant already exist"`
        },
        NO_VARIANT_FEATURE: {
            NAME: 'NO_VARIANT_FEATURE',
            MESSAGE: "errors.400.NO_VARIANT_FEATURE",
            DESCRIPTION: `"Product variant must have at least one feature variant"`
        },
        BRAND_NAME_ALREADY_EXIST: {
            NAME: 'BRAND_NAME_ALREADY_EXIST',
            MESSAGE: "errors.400.BRAND_NAME_ALREADY_EXIST",
            DESCRIPTION: `"The BRAND with name " + name + " already exist!"`
        },
        BRAND_NOT_FOUND: {
            NAME: 'BRAND_NOT_FOUND',
            MESSAGE: "errors.400.BRAND_NOT_FOUND",
            DESCRIPTION: `"The BRAND with id " + id + " was not found"`
        },
        SALE_NOT_FOUND: {
            NAME: 'SALE_NOT_FOUND',
            MESSAGE: "errors.400.SALE_NOT_FOUND",
            DESCRIPTION: `"The Sale with id " + id + " was not found !"`
        },
        SALE_ALREADY_EXIST: {
            NAME: 'SALE_ALREADY_EXIST',
            MESSAGE: "errors.400.SALE_ALREADY_EXIST",
            DESCRIPTION: `"Another sale of the product is still going on !"`
        },
        BAD_ENDING_SALE_DATE: {
            NAME: 'BAD_ENDING_SALE_DATE',
            MESSAGE: "errors.400.BAD_ENDING_SALE_DATE",
            DESCRIPTION: `"The ending date should be after or equal to the starting date!"`
        },
        VARIANT_FEATURE_NOT_FOUND: {
            NAME: 'VARIANT_FEATURE_NOT_FOUND',
            MESSAGE: "errors.400.VARIANT_FEATURE_NOT_FOUND",
            DESCRIPTION: `"The variant_feature with id " + id + " was not found !"`
        },
        ORDER_NOT_PAID: {
            NAME: 'ORDER_NOT_PAID',
            MESSAGE: "errors.400.ORDER_NOT_PAID",
            DESCRIPTION: `"The order with id '" + id + "' was not already paid!"`
        },
        ORDER_NOT_FOUND: {
            NAME: 'ORDER_NOT_FOUND',
            MESSAGE: "errors.400.ORDER_NOT_FOUND",
            DESCRIPTION: `"The order with id '" + id + "' was not found !"`
        },
        PARTNER_NOT_FOUND: {
            NAME: 'PARTNER_NOT_FOUND',
            MESSAGE: "errors.400.PARTNER_NOT_FOUND",
            DESCRIPTION: `"The partner with id " + id + "was not found ! "`
        },
        BANNER_NOT_FOUND: {
            NAME: 'BANNER_NOT_FOUND',
            MESSAGE: "errors.400.BANNER_NOT_FOUND",
            DESCRIPTION: `"The banner with id " + id + "was not found ! "`
        },
        PRODUCT_ALREADY_IN_WISH_LIST: {
            NAME: 'PRODUCT_ALREADY_IN_WISH_LIST',
            MESSAGE: "errors.400.PRODUCT_ALREADY_IN_WISH_LIST",
            DESCRIPTION: `"The PRODUCT with id " + product_id + " is already in the wish list "`
        },
        PRODUCT_IS_NOT_IN_WISH_LIST: {
            NAME: 'PRODUCT_IS_NOT_IN_WISH_LIST',
            MESSAGE: "errors.400.PRODUCT_IS_NOT_IN_WISH_LIST",
            DESCRIPTION: `"The PRODUCT with id " + product_id + " is not inside the wish list!"`
        },
        REVIEW_ALREADY_DONE: {
            NAME: 'REVIEW_ALREADY_DONE',
            MESSAGE: "errors.400.REVIEW_ALREADY_DONE",
            DESCRIPTION: `"The reviews was already done on the product !"`
        },
        BAD_STARS: {
            NAME: 'BAD_STARS',
            MESSAGE: "errors.400.BAD_STARS",
            DESCRIPTION: `"Star has to be  under or equal 5 !"`
        },
        PASSWORD_NOT_MATCH_WITH_OLD: {
            NAME: 'PASSWORD_NOT_MATCH_WITH_OLD',
            MESSAGE: "errors.400.PASSWORD_NOT_MATCH_WITH_OLD",
            DESCRIPTION: `"Old password is not correct"`
        },
    },
};

export const ERROR_401 = 'request.error.401';
export const ERROR_403 = 'request.error.403';
export const ERROR_404 = 'request.error.404';
export const ERROR_500 = 'request.error.500';
export const ERROR_UNKNOWN = 'request.error.unknown';
