/**
 * App Language Provider
 * Add more locales here
 */
// import {getDefaultLanguage} from "Helpers/helpers";
import { createIntlCache, createIntl } from 'react-intl';

import enLang from './entries/en-US';
import frLang from './entries/fr-FR';
// import {initMoment} from "../services/momentService";
import dayjs from "dayjs";
import {getDefaultLanguage} from "../helpers/funcHelper";
require('dayjs/locale/fr');

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

const AppLocale = {
    en: enLang,
    fr: frLang,
};

// Get language for int API
const currentAppLocale = AppLocale[getDefaultLanguage().locale];

if (currentAppLocale.locale === 'fr') {
    dayjs.locale('fr');
}

const intl = createIntl(currentAppLocale, cache);

export const formatMessage = (id, value = {}) => intl.formatMessage({ id, value, });

export default AppLocale;
