import _ from "lodash";
import Type from "../enums/Type";
import CartItem from "./CartItem";
import paymentMethods from "../data/paymentMethods";

export default class Cart {
    constructor(cartItems, voucher, paymentMethod, tax) {
        this.cartItems = cartItems.map(cartItem => new CartItem(cartItem));
        this.totalItems = this.cartItems.length;
        this.voucher = voucher;
        this.paymentMethod = paymentMethod ? paymentMethod : paymentMethods[0];
        this.tax = tax;
    }

    getCartItemByPVId(pvId) {
        return this.cartItems.find(item => item.productVariant.id === pvId);
    }

    getRawTotal(round = true) {
        let totalPrice = 0;
        for (const item of this.cartItems) {
            totalPrice += item.totalPrice;
        }
        return round ? Number(totalPrice.toFixed(2)) : Number(totalPrice);
    }

    getVoucherCalValue(round = true) {
        let result = 0;
        if (this.voucher) {
            if (this.voucher.type === Type.CURRENCY) {
                result = this.voucher.value;
            } else {
                result = (this.getRawTotal() * this.voucher.value) / 100;
            }
        }
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    getTotalWithVoucher(round = true) {
        let totalPrice = this.getRawTotal() - this.getVoucherCalValue();
        return round ? Number(totalPrice.toFixed(2)) : Number(totalPrice);
    }

    getTaxFees(round = true) {
        let result = 0;
        if (this.tax) {
            result = this.getTotalWithVoucher() * this.tax.value / 100;
        }
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    getTotalWithTax(round = true) {
        let result = this.getTotalWithVoucher() + this.getTaxFees();
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    getPaymentFees(round = true) {
        let result = 0;
        if (this.paymentMethod) {
            result = (this.paymentMethod.fees / (100 - this.paymentMethod.fees)) * this.getTotalWithTax();
        }
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    getParticularPaymentFees(paymentMethod, round = true) {
        let result = 0;
        if (paymentMethod) {
            result = (paymentMethod.fees / (100 - paymentMethod.fees)) * this.getTotalWithTax();
        }
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    getTotal(round = true) {
        // Payment fees is not handle by the client so no need to add it again
        let totalPrice = this.getTotalWithTax();

        if (totalPrice <= 0) {
            totalPrice = 0;
        }

        totalPrice += this.getTotalShipping();

        return round ? Number(totalPrice.toFixed(2)) : Number(totalPrice);
    }

    getTotalShipping(round = true) {
        let result = this.cartItems.reduce((acc, b) => acc + b.totalShipping, 0);
        return round ? Number(result.toFixed(2)) : Number(result);
    }

    /**
     * Check if a product variant is into the cart
     * @param productVariantId
     */
    gotPV(productVariantId) {
        return this.cartItems.findIndex(cartItem => cartItem.productVariant.id === productVariantId) !== -1;
    }
}
