import Product from "./Product";
import ProductVariant from "./ProductVariant";

export default class CartItem {
    constructor(cartItem) {
        Object.assign(this, cartItem);
        this.product = new Product(cartItem.product);
        this.productVariant = new ProductVariant(cartItem.productVariant);
    }

    get totalPrice() {
        return this.quantity * this.product.getSalePrice(this.price, false);
    }

    get totalShipping() {
        return this.quantity * this.shipping;
    }
}
