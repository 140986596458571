import {
    SET_LANGUAGE,
    SET_CURRENCY
} from "./action-types";
import {PROFILE} from "../urls/backendUrl";
import {makeRequest} from "../helpers/funcHelper";

export const setLanguage = (language, authUser) => (dispatch) => {
    if (authUser) {
        // Inform the backend about the change
        makeRequest('put', PROFILE.LANGUAGE.UPDATE, {language: language.locale})
            .catch(() => null);
    }

    // Change language
    dispatch({ type: SET_LANGUAGE, payload: language });

    window.location.reload();
};

export const setCurrency = (currency, authUser) => (dispatch) => {
    dispatch({ type: SET_CURRENCY, payload: currency });
    return;
    if (authUser) {
        // Inform the backend about the change
        makeRequest('put', PROFILE.CURRENCIES.UPDATE, {code: currency.code})
            .catch(() => null);
    }

    // Change currency
    console.log("into change ", currency);


    // window.location.reload();
};
