import messages from '../locales/en_US';

const EnLang = {
    messages,
    icon: 'en',
    locale: 'en',
    name: 'English',
    languageId: 'english',
};
export default EnLang;
