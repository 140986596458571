export default class ProductVariant {
    constructor(productVariant) {
        Object.assign(this, productVariant);
        this.variantFeatures = this.variantFeatureList.reduce((acc, b) => {
            acc[b.type] = {name: b.type, value: b.value};
            return acc;
        }, {});
        this.images = productVariant.mediaList;
        this.defaultMI = productVariant.defaultMediaImage || productVariant.mediaList[0];
    }

    getFeatureByType(type) {
        return this.variantFeatureList.find(vf => vf.type === type) || {};
    }

    getOtherMediaImages() {
        return this.mediaList
                .filter(pv => pv.id !== this.defaultMI.id);
    }
}
