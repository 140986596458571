import React from 'react';
import BarLoader from "../loader/BarLoader";

function LandingOverlay( props ) {
    return (
        <div className="app-loader">
            <BarLoader />
        </div>
    );
}

export default LandingOverlay;
