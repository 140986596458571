import {
    SINGLE_PRODUCT,
    SET_CURRENT_PV,
    SHOW_QUICK_VIEW,
    CLOSE_QUICK_VIEW,
    SINGLE_PRODUCT_SUCCESS,
    SINGLE_PRODUCT_FAILURE,
} from "../actions/action-types";
import Product from "../models/Product";

const initialState = {
    data: null,
    error: null,
    loading: false,
    // PV stands for Product Variant
    currentPV: null,
    showQuickView: false,
};

const productReducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case SINGLE_PRODUCT:
            return { ...state, loading: true };

        case SINGLE_PRODUCT_SUCCESS:
            const product = new Product(action.payload);
            let variants = product.productVariantList.sort((a, b) => a.price < b.price);
            let displayedProduct = variants.find(p => p.quantity > 0);
            return {
                ...state,
                loading: false,
                data: product,
                currentPV: displayedProduct ? displayedProduct : variants[0],
            };

        case SINGLE_PRODUCT_FAILURE:
            return { ...state, loading: false, error: action.payload };

        case SHOW_QUICK_VIEW:
            if (action.payload) {
                const product = new Product(action.payload);
                return {
                    ...state,
                    data: product,
                    loading: false,
                    showQuickView: true,
                    currentPV: product.defaultPV,
                }
            }
            return { ...state, showQuickView: true };

        case CLOSE_QUICK_VIEW:
            return { ...state, showQuickView: false };

        case SET_CURRENT_PV:
            if (state.data) {
                const pv = state.data.productVariantList.find(pv => pv.id === action.payload);
                return pv ? {...state, currentPV: pv} : state;
            }
            return state;

        default:
            return state;
    }
};

export default productReducer;
