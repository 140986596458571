module.exports = {
   "billing.address.found" : "{count, plural, =0 {Aucune adresse de facturation trouvée} one {1 adresse de facturation trouvée} other { {count} adresses de facturation trouvées}}",
   "billing.address.create.success" : "Adresse de facturation créée avec succès",
   "billing.address.update.success" : "Adresse de facturation mise à jour avec succès",
   "orders.found" : "{count, plural, =0 {Aucune commande trouvée} one {1 commande trouvée} other { {count} commandes trouvées}}",
   "payment.success.page.title": "Paiement effectué avec succès",
   "payment.success.page.message": "Nous vous avons envoyé une copie de votre reçu",
   "voucher.invalid": "Veuillez saisir un correct code promo",

   "errors.400.USER_EMAIL_ALREADY_USED": "Cet email existe déja",
   "errors.400.USER_WITH_TOKEN_NOT_FOUND": "Aucun utilisteur trouvé avec ce token",
   "errors.400.USER_WITH_EMAIL_NOT_FOUND": "Aucun utilisteur trouvé avec cet email",
   "errors.400.USER_IDENTITY_NOT_PROOF": "Aucun utilisateur connecté",
   "errors.400.ADDRESS_NOT_FOUND": "Cette adresse n'existe pas",
   "errors.400.PROVINCE_NOT_FOUND": "Cette province n'existe pas",
   "errors.400.COUNTRY_NOT_FOUND": "Ce pays n'existe pas",
   "errors.400.PROVINCE_NAME_ALREADY_EXIST": "Une province avec ce nom existe",
   "errors.400.BAD_CATEGORY_PARENT": "Une categorie parent ne peut être parent d'une autre categorie",
   "errors.400.CATEGORY_NAME_ALREADY_EXIST": "Une categorie avec ce nom existe déjà",
   "errors.400.BAD_CATEGORY_PARENT_OWNER": "Une categorie ne peut pas être son propre parent",
   "errors.400.CATEGORY_NOT_FOUND": "Cette categorie n'existe pas",
   "errors.400.CATEGORY_PARENT_NOT_FOUND": "Cette categorie parent n'existe pas",
   "errors.400.PAGE_OR_SIZE_LESS_THAN_ZERO": "La page et la taille pour la pagination doivent être supérieur à zero",
   "errors.400.NULL_USER_AND_VISITOR": "Vous devez spécifier l'id de l'utilisateur ou du visiteur",
   "errors.400.VISITOR_CART_NOT_FOUND": "Le panier de ce visiteur n'existe pas",
   "errors.400.CART_NOT_FOUND": "Le panier n'existe pas",
   "errors.400.COUPON_NOT_FOUND": "Le coupon n'existe pas",
   "errors.400.PRODUCT_NOT_FOUND": "Le produit n'existe pas",
   "errors.400.PRODUCT_VARIANT_NOT_FOUND": "Ce variant n'existe pas",
   "errors.400.QUANTITY_BIGGER_THAN_AVAILABLE": "La quantité de produit variant n'est plus disponible en stock",
   "errors.400.PRODUCT_VARIANT_ALREADY_EXIST": "Ce variant existe déjà",
   "errors.400.NO_VARIANT_FEATURE": "Le produit variant doit avoir au moins un feature variant",
   "errors.400.BRAND_NAME_ALREADY_EXIST": "Une marque avec ce nom existe déjà",
   "errors.400.BRAND_NOT_FOUND": "Cette marque n'existe pas",
   "errors.400.SALE_NOT_FOUND": "Cette solde n'existe pas",
   "errors.400.SALE_ALREADY_EXIST": "Une solde est déja en cours",
   "errors.400.BAD_ENDING_SALE_DATE": "La date de fin de la solde doit être après sa date de debut ",
   "errors.400.VARIANT_FEATURE_NOT_FOUND": "Le variant feature n'existe pas",
   "errors.400.ORDER_NOT_PAID": "La commande n'est pas encore payée",
   "errors.400.ORDER_NOT_FOUND": "La commande n'existe pas",
   "errors.400.PARTNER_NOT_FOUND": "Le partenaire n'existe pas",
   "errors.400.BANNER_NOT_FOUND": "La bannière n'existe pas",
   "errors.400.PRODUCT_ALREADY_IN_WISH_LIST": "Ce produit est déjà ajouté à la whisList",
   "errors.400.PRODUCT_IS_NOT_IN_WISH_LIST": "Ce produit n'est pas dans la whisList",
   "errors.400.REVIEW_ALREADY_DONE": "Un review est déja fait sur ce produit",
   "errors.400.BAD_STARS": "La note doit être inférieur ou égale à 5",
   "errors.400.PASSWORD_NOT_MATCH_WITH_OLD": "L'ancien mot de passe n'est pas correct",

   "auth.person": "Personne",
   "auth.organisation": "Organisation",
   "auth.email": "Email",
   "auth.password": "Mot de passe",
   "auth.passwordConfirmation": "Confirmation du Mot de passe",
   "auth.phoneNumber": "Numéro de téléphone",
   "step.step1": "Étape 1",
   "step.step2": "Étape 2",
   "step.step3": "Étape 3",
   "form.min": "Min",
   "form.max": "Max",
   "form.error.verify.min": "Veuillez vérifier la valeur minimale",
   "form.error.verify.minAndMax": "Veuillez vérifier la valeur minimale et maximale",
   "form.error.correct.min": "Veuillez vérifier la valeur minimale",
   "form.error.correct.number": "Veuillez saisir un nombre correct",
   "form.error.requiredField": "Ce champ est requis",
   "form.error.validEmail": "Veuillez entrer une adresse email valide",
   "form.error.validDate": "Veuillez entrer une date valide",
   "form.error.minCharacters": "Veuillez saisir au moins {min} caractère(s)",
   "form.error.maxCharacters": "Veuillez saisir au maximum {min} caractère(s)",
   "form.error.passwordConfirmation": "Les mots de passe doivent être les identiques",
   "form.error.date.valid": "{date} doit être une adte valide",
   "form.error.date.min": "Vous devez avoir plus de {minAge} ans pour créer un compte",
   "form.error.date.maximumDate": "{currentDate} ne doit pas être supérieur à {maximumDate}",
   "date.validity.start": "Debut de validité",
   "date.validity.end": "Fin de validité",
   "date.birth": "La date de naissance",
   "date.today": "La date d'aujoudh'ui",
   "auth.createAccount": "Créer un nouveau compte?",
   "auth.haveAccount": "Vous avez déjà un compte?",
   "auth.haveAccountLogin": "Vous avez déjà un compte? Connectez-vous",
   "auth.login.title": "Debutez sur {name}",
   "auth.login.subTitle": "Mon slogan intéressant ici",
   "auth.termOfService": "En vous inscrivant sur {name}, vous acceptez nos",
   "auth.signin": "Se connecter",
   "auth.signup": "S'inscrire",
   "auth.resetPasswordLink.btnText": "Réinitialiser le mot de passe",
   "auth.resetPassword.btnText": "Mettre à jour le mot de passe",
   "auth.resetPasswordLink.title": "Réinitialisez votre mot de passe",
   "auth.resetPasswordLink.subTitle": "Entrez votre email et nous vous enverrons un lien de réinitialisation de mot de passe",
   "auth.resetPasswordLink.successText": "Vous venez de vous envoyer un e-mail. Veuillez le vérifier",
   "auth.resetPassword.title": "Mettez à jour votre mot de passe",
   "auth.resetPassword.subTitle": "Entrez votre nouveau mot de passe",
   "auth.resetPassword.successText": "Mot de passe réinitialisé avec succès. Veuillez vous connecter",
   "auth.resetPassword.errorToken": "Paramètres d'URL incorrects. Veuillez vérifier vos paramètres",
   "error.500": "Une erreur s'est produite, réessayez plus tard.",
   "common.termOfService": "Conditions d'utilisation",
   "common.nationality": "Nationalité",
   "common.residenceCountry": "Pays de résidence",
   "common.operator": "Opérateur",
   "common.identificationType": "Type d'identification",
   "common.identificationNumber": "Numéro d'identification",
   "common.socialReason": "Raison social",
   "common.commercialName": "Nom commercial",
   "common.organisationType": "Type d'organisation",
   "common.registrationCountry": "Pays d'immatriculation",
   "common.registrationType": "Type d'immatriculation",
   "common.registrationNumber": "Numéro d'immatriculation",
   "common.registrationBeginningDate": "Date du debut d'immatriculation",
   "common.agreeTermsConditions": "J'accepte nos Conditions d'utilisation",
   "common.acceptTermsAndConditions": "Vous devez accepter nos Conditions d'utilisation",
   "sidebar.app": "App",
   "sidebar.horizontal": "Horizontal",
   "sidebar.horizontalMenu": "Menu horizontal",
   "sidebar.general": "Général",
   "sidebar.component": "Composant",
   "sidebar.features": "Caractéristiques",
   "sidebar.applications": "Applications",
   "sidebar.dashboard": "Tableau de bord",
   "sidebar.branch": "Branche",
   "sidebar.configuration": "Configuration",
   "sidebar.coverage": "Couverture",
   "sidebar.catalog": "Catalogue",
   "sidebar.create": "Creation",
   "sidebar.product": "Produit",
   "sidebar.category": "Categories",
   "sidebar.productType": "Type de produit",
   "sidebar.productCategory": "Catégorie de produit",
   "sidebar.products": "Produits",
   "sidebar.catalogProducts": "Produits du catalogue",
   "sidebar.catalogSales": "Ventes de catalogue",
   "sidebar.catalogDistribution": "Distribution du catalogue",
   "widgets.active": "Active",
   "sidebar.dashboard1": "Tableau de bord 1",
   "sidebar.dashboard2": "Tableau de bord 2",
   "sidebar.dashboard3": "Tableau de bord 3",
   "sidebar.modules": "Modules",
   "sidebar.agency": "Agence",
   "sidebar.pages": "Pages",
   "sidebar.gallery": "Galerie",
   "sidebar.pricing": "Tarification",
   "sidebar.terms&Conditions": "Termes et conditions",
   "sidebar.feedback": "Retour d'information",
   "sidebar.report": "rapport",
   "sidebar.faq(s)": "Faq (s)",
   "sidebar.advancedComponent": "Composant avancé",
   "sidebar.blank": "Blanc",
   "sidebar.session": "Session",
   "sidebar.login": "S'identifier",
   "sidebar.register": "Registre",
   "sidebar.lockScreen": "Écran verrouillé",
   "sidebar.forgotPassword": "Mot de passe oublié",
   "sidebar.404": "404",
   "sidebar.500": "500",
   "sidebar.uiComponents": "Composants de l'interface utilisateur",
   "sidebar.alerts": "Alertes",
   "sidebar.appBar": "App Bar",
   "sidebar.avatars": "Avatars",
   "sidebar.buttons": "Boutons",
   "sidebar.bottomNavigations": "Navigation par le bas",
   "sidebar.badges": "Badges",
   "sidebar.cards": "Cartes",
   "sidebar.cardsMasonry": "Cartes Maçonnerie",
   "sidebar.chip": "Puce",
   "sidebar.dialog": "Dialogue",
   "sidebar.dividers": "Intercalaires",
   "sidebar.drawers": "Tiroirs",
   "sidebar.popover": "Popover",
   "sidebar.expansionPanel": "Panneau d'extension",
   "sidebar.gridList": "Grille Liste",
   "sidebar.list": "liste",
   "sidebar.menu": "Menu",
   "sidebar.popoverAndToolTip": "Pop Over et pointe d'outil",
   "sidebar.progress": "Le progrès",
   "sidebar.snackbar": "Snackbar",
   "sidebar.selectionControls": "Contrôles de sélection",
   "sidebar.advanceUiComponents": "Composants d'interface utilisateur avancés",
   "sidebar.dateAndTimePicker": "Sélecteur de date et d'heure",
   "sidebar.tabs": "onglets",
   "sidebar.stepper": "stepper",
   "sidebar.notification": "Notification",
   "sidebar.sweetAlert": "Alerte douce",
   "sidebar.autoComplete": "auto complet",
   "sidebar.aboutUs": "À propos de nous",
   "sidebar.widgets": "widgets",
   "sidebar.forms": "Formes",
   "sidebar.formElements": "Éléments de formulaire",
   "sidebar.textField": "Champ de texte",
   "sidebar.selectList": "Sélectionner la liste",
   "sidebar.charts": "Graphiques",
   "sidebar.reCharts": "Re Graphiques",
   "sidebar.reactChartjs2": "Réagir Chartjs 2",
   "sidebar.icons": "Icônes",
   "sidebar.themifyIcons": "Thémifier des icônes",
   "sidebar.simpleLineIcons": "Icônes de ligne simple",
   "sidebar.fontAwesome": "Police impressionnante",
   "sidebar.materialIcons": "Icônes de matériel",
   "sidebar.tables": "les tables",
   "sidebar.basic": "De base",
   "sidebar.dataTable": "Tableau de données",
   "sidebar.responsive": "Sensible",
   "sidebar.reactTable": "Réagir à la table",
   "sidebar.maps": "Des plans",
   "sidebar.googleMaps": "Google Plans",
   "sidebar.leafletMaps": "Cartes de brochures",
   "sidebar.inbox": "Boîte de réception",
   "sidebar.users": "Utilisateurs",
   "sidebar.userProfile1": "Profil de l'utilisateur 1",
   "sidebar.userProfile2": "Profil d'utilisateur 2",
   "sidebar.userManagement": "Gestion des utilisateurs",
   "sidebar.userProfile": "Profil de l'utilisateur",
   "sidebar.userList": "liste d'utilisateur",
   "sidebar.calendar": "Calendrier",
   "sidebar.cultures": "Des cultures",
   "sidebar.dnd": "Dnd",
   "sidebar.selectable": "Sélectionnable",
   "sidebar.customRendering": "Rendu personnalisé",
   "sidebar.chat": "Bavarder",
   "sidebar.toDo": "Faire",
   "sidebar.editor": "Éditeur",
   "sidebar.wysiwygEditor": "Éditeur WYSIWYG",
   "sidebar.quillEditor": "Éditeur de piquants",
   "sidebar.reactAce": "Réagir Ace",
   "sidebar.dragAndDrop": "Glisser déposer",
   "sidebar.reactDragula": "Réagir Dragula",
   "sidebar.reactDnd": "Réagir Dnd",
   "sidebar.blogManagement": "Gestion de blog",
   "sidebar.ecommerce": "Commerce électronique",
   "sidebar.shopList": "Liste de magasins",
   "sidebar.shopGrid": "Boutique Grid",
   "sidebar.invoice": "Facture d'achat",
   "sidebar.multilevel": "multiniveau",
   "sidebar.sublevel": "sous-niveau",
   "sidebar.usersProfile": "Profile utilisateurs",
   "widgets.totalEarns": "total gagne",
   "widgets.emailsStatistics": "Statistiques des emails",
   "widgets.totalRevenue": "Revenu total",
   "widgets.onlineVistors": "Visiteurs en ligne",
   "widgets.trafficSources": "Sources de trafic",
   "widgets.RecentOrders": "Dernières commandes",
   "widgets.topSellings": "Meilleures ventes",
   "widgets.productReports": "Rapports de produit",
   "widgets.productStats": "Statistiques du produit",
   "widgets.ComposeEmail": "Écrire un email",
   "widgets.employeePayroll": "Employee Payroll",
   "widgets.visitors": "Visiteurs",
   "widgets.orders": "Ordres",
   "widgets.orderStatus": "Statut de la commande",
   "widgets.totalSales": "Ventes totales",
   "widgets.ratings": "Évaluations",
   "widgets.netProfit": "Bénéfice net",
   "widgets.overallTrafficStatus": "État général du trafic",
   "widgets.tax": "Impôt",
   "widgets.expenses": "Expenses",
   "widgets.currentTime": "Heure actuelle",
   "widgets.currentDate": "Date actuelle",
   "widgets.todayOrders": "Commandes d'aujourd'hui",
   "widgets.toDoList": "Faire des listes",
   "widgets.discoverPeople": "Découvrir les gens",
   "widgets.commments": "commentaires",
   "widgets.newCustomers": "nouveaux clients",
   "widgets.recentNotifications": "Notifications récentes",
   "widgets.appNotifications": "Notifications d'application",
   "widgets.newEmails": "Nouveaux emails",
   "widgets.siteVisitors": "Visiteurs du site",
   "widgets.socialCompanines": "Entreprises sociales",
   "widgets.recentActivities": "Activités récentes",
   "widgets.recentOrders": "Dernières commandes",
   "widgets.gallery": "Galerie",
   "widgets.pricing": "Tarification",
   "widgets.enterpriseEdition": "Edition pour entreprise",
   "widgets.personalEdition": "Édition personnelle",
   "widgets.teamEdition": "Édition d'équipe",
   "widgets.standard": "la norme",
   "widgets.advanced": "Avancée",
   "widgets.master": "Maîtriser",
   "widgets.Mega": "Mega",
   "widgets.logIn": "s'identifier",
   "widgets.signUp": "S'inscrire",
   "widgets.lockScreen": "Écran verrouillé",
   "widgets.alertsWithLink": "Alertes avec lien",
   "widgets.additionalContent": "Contenu additionnel",
   "widgets.alertDismiss": "Refus d'alerte",
   "widgets.uncontrolledDisableAlerts": "Désactiver les alertes non contrôlées",
   "widgets.contexualAlerts": "Alertes contextuelles",
   "widgets.alertsWithIcons": "Alertes avec des icônes",
   "widgets.Simple App Bars": "Barres d'application simples",
   "widgets.appBarsWithButtons": "Barres d'applications avec boutons",
   "widgets.imageAvatars": "Avatars d'images",
   "widgets.lettersAvatars": "Lettres Avatars",
   "widgets.iconsAvatars": "Avatars d'icônes",
   "widgets.flatButtons": "Boutons plats",
   "widgets.raisedButton": "Bouton surélevé",
   "widgets.buttonWithIconAndLabel": "Bouton avec icône et étiquette",
   "widgets.floatingActionButtons": "Boutons d'action flottants",
   "widgets.iconButton": "Bouton d'icône",
   "widgets.socialMediaButton": "Bouton Médias sociaux",
   "widgets.reactButton": "Bouton Réagir",
   "widgets.buttonOutline": "Contour du bouton",
   "widgets.buttonSize": "Taille du bouton",
   "widgets.buttonState": "Etat du bouton",
   "widgets.buttonNavigationWithNoLabel": "bouton Navigation sans étiquette",
   "widgets.buttonNavigation": "Bouton de navigation",
   "widgets.iconNavigation": "Icône de navigation",
   "widgets.badgeWithHeadings": "Badge avec des titres",
   "widgets.contexualVariations": "Variations Contextuelles",
   "widgets.badgeLinks": "Liens de Badge",
   "widgets.materialBadge": "Badge de matériel",
   "widgets.simpleCards": "Cartes simples",
   "widgets.backgroundVarient": "Varient d'arrière-plan",
   "widgets.cardOutline": "Carte contour",
   "widgets.overlayCard": "Carte de superposition",
   "widgets.cardGroup": "Groupe de cartes",
   "widgets.cardTitle": "Titre de la carte",
   "widgets.speacialTitleTreatment": "Traitement du titre speacial",
   "widgets.chipWithClickEvent": "Puce avec Click Click",
   "widgets.chipArray": "Chip Array",
   "widgets.dialogs": "Dialogues",
   "widgets.listDividers": "List Diviseurs",
   "widgets.insetDividers": "Intercalaires",
   "widgets.temporaryDrawers": "Tiroirs temporaires",
   "widgets.permanentDrawers": "Tiroirs permanents",
   "widgets.simpleExpansionPanel": "Panneau d'extension simple",
   "widgets.controlledAccordion": "Accordéon contrôlé",
   "widgets.secondaryHeadingAndColumns": "En-tête secondaire et colonnes",
   "widgets.imageOnlyGridLists": "Listes de grille d'images seulement",
   "widgets.advancedGridLists": "Listes de grille avancées",
   "widgets.singleLineGridLists": "Listes de grille unifilaires",
   "widgets.simpleLists": "Listes simples",
   "widgets.folderLists": "Listes de dossiers",
   "widgets.listItemWithImage": "Elément de liste avec image",
   "widgets.switchLists": "Commuter les listes",
   "widgets.insetLists": "Listes d'encart",
   "widgets.nestedLists": "Listes imbriquées",
   "widgets.checkboxListControl": "Contrôle de liste de cases à cocher",
   "widgets.pinedSubHeader": "Sous-en-tête",
   "widgets.InteractiveLists": "Listes interactives",
   "widgets.simpleMenus": "Menus simples",
   "widgets.selectedMenu": "Menu sélectionné",
   "widgets.maxHeightMenu": "Max Height Menu",
   "widgets.changeTransition": "Changer la transition",
   "widgets.paper": "Papier",
   "widgets.anchorPlayGround": "Terrain de jeu d'ancre",
   "widgets.tooltip": "Info-bulle",
   "widgets.positionedToolTips": "Snackbar positionné",
   "widgets.circularProgressBottomStart": "Progression circulaire Début inférieur",
   "widgets.interactiveIntegration": "Intégration interactive",
   "widgets.determinate": "Déterminé",
   "widgets.linearProgressLineBar": "Barre de progression linéaire",
   "widgets.indeterminate": "Indéterminé",
   "widgets.buffer": "Tampon",
   "widgets.query": "Question",
   "widgets.transitionControlDirection": "Direction du contrôle de la transition",
   "widgets.simpleSnackbar": "Snackbar simple",
   "widgets.positionedSnackbar": "Snackbar positionné",
   "widgets.contexualColoredSnackbars": "Snackbars colorés contexuels",
   "widgets.simpleCheckbox": "Case à cocher simple",
   "widgets.interminateSelection": "Sélection interminable",
   "widgets.disabledCheckbox": "Case à cocher désactivée",
   "widgets.customColorCheckbox": "Case à cocher de couleur personnalisée",
   "widgets.VerticalStyleCheckbox": "Case à cocher Style vertical",
   "widgets.horizontalStyleCheckbox": "Case à cocher Style horizontal",
   "widgets.radioButtons": "Badges publicitaires",
   "widgets.disabledRadio": "Radio désactivée",
   "widgets.withError": "Avec erreur",
   "widgets.switches": "Swiches",
   "widgets.dateAndTimePicker": "Sélecteur de date et d'heure",
   "widgets.defaultPicker": "Sélection par défaut",
   "widgets.timePicker": "Time Picker",
   "widgets.weekPicker": "Sélecteur de semaine",
   "widgets.defaultDatePicker": "Sélecteur de date par défaut",
   "widgets.customPicker": "Sélecteur personnalisé",
   "widgets.tabs": "Tabs",
   "widgets.fixedTabs": "Onglets fixes",
   "widgets.basicTab": "Onglet Basic",
   "widgets.wrappedLabels": "Étiquettes enveloppées",
   "widgets.centeredLabels": "Étiquettes centrées",
   "widgets.forcedScrolledButtons": "Boutons défilants forcés",
   "widgets.iconsTabs": "Onglets d'icônes",
   "widgets.withDisableTabs": "Avec les onglets Désactiver",
   "widgets.iconWithLabel": "Icône avec étiquette",
   "widgets.stepper": "Stepper",
   "widgets.horizontalLinear": "Linéaire linéaire",
   "widgets.horizontalNonLinear": "Horizontal non linéaire",
   "widgets.horizontalLinerAlternativeLabel": "Étiquette alternative de doublure horizontale",
   "widgets.horizontalNonLinerAlternativeLabel": "Étiquette alternative horizontale non doublure",
   "widgets.verticalStepper": "Stepper vertical",
   "widgets.descriptionAlert": "Description Alerte",
   "widgets.customIconAlert": "Alerte d'icône personnalisée",
   "widgets.withHtmlAlert": "avec Html Alert",
   "widgets.promptAlert": "Alerte rapide",
   "widgets.passwordPromptAlert": "Alerte de mot de passe",
   "widgets.customStyleAlert": "Alerte de style personnalisé",
   "widgets.autoComplete": "Auto complet",
   "widgets.reactSelect": "React Select",
   "widgets.downshiftAutoComplete": "Réacheminement automatique terminé",
   "widgets.reactAutoSuggests": "Réagissez Auto Suggests",
   "widgets.aboutUs": "À propos de nous",
   "widgets.ourVission": "Notre vision",
   "widgets.ourMissions": "Nos missions",
   "widgets.ourMotivation": "Notre motivation",
   "widgets.defualtReactForm": "Defualt React Form",
   "widgets.url": "Url",
   "widgets.textArea": "Zone de texte",
   "widgets.file": "Fichier",
   "widgets.formGrid": "Grille de formulaire",
   "widgets.inlineForm": "Formulaire en ligne",
   "widgets.inputSizing": "Dimensionnement d'entrée",
   "widgets.inputGridSizing": "Dimensionnement de la grille d'entrée",
   "widgets.hiddenLabels": "Étiquettes cachées",
   "widgets.formValidation": "Validation de formulaire",
   "widgets.number": "Nombre",
   "widgets.date": "Rendez-vous amoureux",
   "widgets.time": "Temps",
   "widgets.color": "Couleur",
   "widgets.search": "Chercher",
    "widgets.search1": "Rechercher un produit ...",
   "widgets.selectMultiple": "Sélectionnez plusieurs",
   "widgets.inputWithSuccess": "Entrer avec succès",
   "widgets.inputWithDanger": "Entrée avec danger",
   "widgets.simpleTextField": "Champ de texte simple",
   "widgets.componet": "Composants",
   "widgets.layouts": "Layouts",
   "widgets.inputAdorements": "Adorations d'entrée",
   "widgets.formattedInputs": "Entrées formatées",
   "widgets.simpleSelect": "Simple Select",
   "widgets.nativeSelect": "Sélection native",
   "widgets.MutltiSelectList": "Liste de sélection Mutlti",
   "widgets.lineChart": "Graphique en ligne",
   "widgets.barChart": "Diagramme à bandes",
   "widgets.stackedBarChart": "Graphique à barres empilées",
   "widgets.lineBarAreaChart": "Graphique de la barre de ligne",
   "widgets.areaChart": "Graphique de zone",
   "widgets.stackedAreaChart": "Graphique de zone empilée",
   "widgets.verticalChart": "Graphique vertical",
   "widgets.radarChart": "Graphique radar",
   "widgets.doughnut": "Beignet",
   "widgets.polarChart": "Graphique polaire",
   "widgets.pieChart": "Camembert",
   "widgets.bubbleChart": "Graphique à bulles",
   "widgets.horizontalBar": "Barre horizontale",
   "widgets.basicTable": "Table de base",
   "widgets.contexualColoredTable": "Table colorée contexuelle",
   "widgets.dataTable": "Tableau de données",
   "widgets.employeeList": "Liste des employés",
   "widgets.responsiveTable": "Responsive Table",
   "widgets.responsiveFlipTable": "Table à bascule souple",
   "widgets.reactGridControlledStateMode": "Réagir le mode d'état contrôlé par la grille",
   "widgets.googleMaps": "Google Maps",
   "widgets.productsReports": "Rapports de produits",
   "widgets.taskList": "Liste de tâches",
   "widgets.basicCalender": "Calendrier basique",
   "widgets.culturesCalender": "Cultures Calendrier",
   "widgets.dragAndDropCalender": "Drag and Drop Calender",
   "widgets.selectableCalender": "Calendrier sélectionnable",
   "widgets.customRendering": "Rendu personnalisé",
   "widgets.customCalender": "Calendrier personnalisé",
   "widgets.searchMailList": "Rechercher une liste de courrier",
   "components.buyNow": "Acheter maintenant",
   "compenets.choose": "Choisir",
   "compenets.username": "Nom d'utilisateur",
   "compenets.passwords": "Mots de passe",
   "widgets.forgetPassword": "Mot de passe oublié",
   "compenets.signIn": "Se connecter",
   "compenets.dontHaveAccountSignUp": "N'avez pas d'inscription de compte",
   "compenets.enterUserName": "Saisissez votre nom d'utilisateur",
   "compenets.enterEmailAddress": "Entrer l'adresse e-mail",
   "compenets.confirmPasswords": "Confirmer les mots de passe",
   "components.alreadyHavingAccountSignIn": "Vous avez déjà un compte Connexion",
   "components.enterYourPassword": "Tapez votre mot de passe",
   "components.unlock": "Ouvrir",
   "components.enterPasswords": "Entrer les mots de passe",
   "components.resetPassword": "réinitialiser le mot de passe",
   "components.pageNotfound": "Page non trouvée",
   "components.goToHomePage": "Aller à la page d'accueil",
   "components.sorryServerGoesWrong": "Désolé, le serveur va mal",
   "components.persistentDrawer": "Tiroir persistant",
   "components.back": "Arrière",
   "components.next": "Prochain",
   "components.completeStep": "Étape complète",
   "components.withHtml": "Avec Html",
   "components.prompt": "Rapide",
   "components.withDescription": "Avec description",
   "components.success": "Succès",
   "components.passwordPrompt": "Invite de mot de passe",
   "components.warning": "Attention",
   "components.customIcon": "Icône personnalisée",
   "components.customStyle": "Style personnalisé",
   "components.basic": "De base",
   "components.submit": "Soumettre",
   "components.compose": "Composer",
   "components.sendMessage": "Envoyer le message",
   "components.addNewTasks": "Ajouter de nouvelles tâches",
   "components.addToCart": "Ajouter au panier",
   "components.payNow": "Payez maintenant",
   "components.print": "Impression",
   "components.cart": "Panier",
   "components.viewCart": "Voir le panier",
   "components.checkout": "Check-out",
   "widgets.QuickLinks": "Liens rapides",
   "widgets.upgrade": "améliorer",
   "widgets.app": "App",
   "widgets.addNew": "Ajouter un nouveau",
   "widgets.orderDate": "Date de commande",
   "widgets.status": "Statut",
   "widgets.trackingNumber": "Numéro de suivi",
   "widgets.action": "action",
   "widgets.designation": "La désignation",
   "widgets.subject": "assujettir",
   "widgets.send": "Envoyer",
   "widgets.saveAsDrafts": "Enregistrer en tant que brouillons",
   "widgets.onlineSources": "Sources en ligne",
   "widgets.lastMonth": "Le mois dernier",
   "widgets.widgets": "Widgets",
   "widgets.listing": "Inscription",
   "widgets.paid": "Payé",
   "widgets.refunded": "Remboursé",
   "widgets.done": "Terminé",
   "widgets.pending": "en attendant",
   "widgets.cancelled": "Annulé",
   "widgets.approve": "Approuver",
   "widgets.following": "Suivant",
   "widgets.follow": "suivre",
   "widgets.graphs&Charts": "Graphiques et graphiques",
   "widgets.open": "Ouvrir",
   "widgets.bounced": "Rebondi",
   "widgets.spam": "Spam",
   "widgets.unset": "Annuler",
   "widgets.bandwidthUse": "Utilisation de la bande passante",
   "widgets.dataUse": "Utilisation de données",
   "widgets.unsubscribe": "Se désabonner",
   "widgets.profile": "Profil",
   "widgets.messages": "messages",
   "widgets.support": "Soutien",
   "widgets.faq(s)": "Faq (s)",
   "widgets.upgradePlains": "Mettre à niveau les plaines",
   "widgets.logOut": "Deconnexion",
   "widgets.mail": "Courrier",
   "widgets.adminTheme": "Thème Admin",
   "widgets.wordpressTheme": "Thème Wordpress",
   "widgets.addToCart": "Ajouter au panier",
   "widgets.plan": "Plan",
   "widgets.basic": "De base",
   "widgets.pro": "Pro",
   "widgets.startToBasic": "Début à la base",
   "widgets.upgradeToPro": "Passer à Pro",
   "widgets.upgradeToAdvance": "Mettre à niveau à l'avance",
   "widgets.comparePlans": "Comparer les plans",
   "widgets.free": "Gratuit",
   "widgets.frequentlyAskedQuestions": "Questions fréquemment posées",
   "widgets.searchIdeas": "Rechercher des idées",
   "widgets.startDate": "Date de début",
   "widgets.endDate": "Date de fin",
   "widgets.category": "Catégorie",
   "widgets.apply": "Appliquer",
   "widgets.downloadPdfReport": "Télécharger le rapport Pdf",
   "widgets.yesterday": "Hier",
   "widgets.totalOrders": "Total des commandes",
   "widgets.totalVisitors": "Nombre total de visiteurs",
   "widgets.typeYourQuestions": "Tapez vos questions",
   "widgets.username": "Nom d'utilisateur",
   "widgets.password": "Mot de passe",
   "widgets.signIn": "Se connecter",
   "widgets.enterYourPassword": "Tapez votre mot de passe",
   "widgets.alreadyHavingAccountLogin": "Vous avez déjà un identifiant de compte",
   "widgets.composeMail": "Écrire un email",
   "widgets.issue": "Problème",
   "widgets.recentChat": "Chat récent",
   "widgets.previousChat": "Chat précédent",
   "widgets.all": "Tout",
   "widgets.filters": "Filtres",
   "widgets.deleted": "Supprimé",
   "widgets.starred": "Marqué",
   "widgets.frontend": "L'extrémité avant",
   "widgets.backend": "Backend",
   "widgets.api": "Api",
   "widgets.simpleAppBar": "Barre d'application simple",
   "widgets.recents": "Récents",
   "widgets.cardLink": "Lien de carte",
   "widgets.anotherLink": "Un autre lien",
   "widgets.cardSubtitle": "Sous-titre de la carte",
   "widgets.confirmationDialogs": "Dialogues de confirmation",
   "widgets.deletableChip": "Puce Deletable",
   "widgets.customDeleteIconChip": "Chip d'icône de suppression personnalisée",
   "widgets.openAlertDialog": "Ouvrir la boîte de dialogue d'alerte",
   "widgets.openResponsiveDialog": "Ouvrir le dialogue réactif",
   "widgets.openSimpleDialog": "Ouvrir une boîte de dialogue simple",
   "widgets.openFormDialog": "Ouvrir la boîte de dialogue de formulaire",
   "widgets.follower": "Disciple",
   "widgets.important": "Important",
   "widgets.private": "Privé",
   "widgets.openLeft": "Ouvrir à gauche",
   "widgets.openRight": "Ouvert à droite",
   "widgets.openTop": "Toit ouvert",
   "widgets.openBottom": "Fond ouvert",
   "widgets.selectTripDestination": "Sélectionnez la destination du voyage",
   "widgets.pinnedSubheaderList": "Liste de sous-titres épinglés",
   "widgets.singleLineItem": "Élément de ligne unique",
   "widgets.acceptTerms": "Accepter les termes",
   "widgets.optionA": "Option A",
   "widgets.optionB": "Option B",
   "widgets.optionC": "Option C",
   "widgets.optionM": "Option M",
   "widgets.optionN": "Option N",
   "widgets.optionO": "Option O",
   "widgets.customColor": "Couleur personnalisée",
   "widgets.centeredTabs": "Onglets centrés",
   "widgets.multipleTabs": "Onglets multiples",
   "widgets.preventScrolledButtons": "Empêcher les boutons défilés",
   "widgets.browse": "Feuilleter",
   "widgets.formValidate": "Formulaire de validation",
   "widgets.code": "Code",
   "widgets.company": "Compagnie",
   "widgets.price": "Prix",
   "widgets.change": "Changement",
   "widgets.high": "Haute",
   "widgets.low": "Faible",
   "widgets.volume": "Le volume",
   "widgets.personalDetails": "Détails personnels",
   "widgets.occupation": "Occupation",
   "widgets.companyName": "Nom de la compagnie",
   "widgets.phoneNo": "Pas de téléphone",
   "widgets.city": "Ville",
   "widgets.zipCode": "Code postal",
   "widgets.updateProfile": "Mettre à jour le profil",
   "widgets.reject": "Rejeter",
   "widgets.exportToExcel": "Exporter vers Excel",
   "widgets.addNewUser": "Ajouter un nouvel utilisateur",
   "widgets.workWeek": "Semaine de travail",
   "widgets.agenda": "Ordre du jour",
   "widgets.conference": "Conférence",
   "widgets.multilevel": "Multilevel",
   "widgets.dailySales": "Ventes quotidiennes",
   "widgets.today": "Aujourd'hui",
   "widgets.campaignPerformance": "Performance de la campagne",
   "widgets.supportRequest": "Demande de soutien",
   "widgets.usersList": "Liste des utilisateurs",
   "widgets.lastWeek": "La semaine dernière",
   "themeOptions.sidebarOverlay": "Superposition de la barre latérale",
   "themeOptions.sidebarBackgroundImages": "Images de fond de la barre latérale",
   "themeOptions.sidebarImage": "Image de la barre latérale",
   "themeOptions.miniSidebar": "Mini barre latérale",
   "themeOptions.boxLayout": "Box Layout",
   "themeOptions.rtlLayout": "Rtl Layout",
   "themeOptions.darkMode": "Mode sombre",
   "themeOptions.appSettings": "Paramètres de l'application",
   "themeOptions.sidebarLight": "Lumière",
   "themeOptions.sidebarDark": "Foncé",
   "button.cancel": "Annuler",
   "button.add": "Ajouter",
   "button.update": "Mettre à jour",
   "button.reply": "Répondre",
   "button.delete": "Effacer",
   "button.yes": "Oui",
   "button.viewAll": "Voir tout",
   "button.like": "Comme",
   "button.assignNow": "Assigner maintenant",
   "button.declare": "Declarer",
   "button.reset": "Réinitialiser",
   "button.start": "Demarer",
   "button.activate": "Activer",
   "button.seeInsights": "Voir Insights",
   "sidebar.dateTimePicker": "Sélecteur de date et d'heure",
   "components.summary": "Résumé",
   "hint.whatAreYouLookingFor": "Que recherchez-vous",
   "components.yesterday": "Hier",
   "components.last7Days": "Les 7 derniers jours",
   "components.last1Month": "Le dernier mois",
   "components.last6Month": "Les 6 derniers mois",
   "components.spaceUsed": "Espace utilisé",
   "components.followers": "disciple",
   "components.trending": "Tendance",
   "components.paid": "Payé",
   "components.refunded": "Remboursé",
   "components.done": "Terminé",
   "components.pending": "En attente",
   "components.cancelled": "Annulé",
   "components.approve": "Approuver",
   "components.week": "Semaine",
   "components.month": "Month",
   "components.year": "Année",
   "components.today": "Aujourd'hui",
   "components.popularity": "Popularité",
   "components.email": "Email",
   "components.drafts": "brouillons",
   "components.sent": "Envoyé",
   "components.trash": "Corbeille",
   "components.all": "Tous",
   "components.do": "faire",
   "components.title": "Titre",
   "components.projectName": "Nom du projet",
   "components.companyName": "Nom de l'entreprise",
   "components.openAlert": "Ouvrir l'alerte",
   "components.slideInAlertDialog": "Diapositive dans la boîte de dialogue d'alerte",
   "components.openFullScreenDialog": "Ouvrir les dialogues plein écran",
   "components.basicChip": "Puce de base",
   "components.clickableChip": "Cliquable",
   "components.left": "Gauche",
   "components.right": "Droit",
   "components.expansionPanel1": "Panneau d'extension 1",
   "components.expansionPanel2": "Panneau d'extension 2",
   "components.generalSetting": "Paramètre général",
   "components.advancedSettings": "Paramètres avancés",
   "components.name": "Nom",
   "components.firstName": "Prénom",
   "components.lastName": "Nom de famille",
   "components.occupation": "Occupation",
   "components.phoneNo": "Numéro de téléphone",
   "components.address": "Adresse",
   "components.city": "Ville",
   "components.state": "Etat",
   "components.zipCode": "Code postal",
   "components.social Connection": "Connexion sociale",
   "widgets.buyMore": "Acheter plus",
   "widgets.trafficChannel": "Canal de trafic",
   "widgets.stockExchange": "Bourse",
   "widgets.tweets": "Tweets",
   "widgets.ourLocations": "Nos sites",
   "widgets.sales": "Ventes",
   "widgets.to": "Pour",
   "widgets.shipTo": "Expédier à",
   "widgets.description": "Description",
   "widgets.unitPrice": "Prix unitaire",
   "widgets.total": "total",
   "widgets.note": "Remarque",
   "widgets.chipWithAvatar": "Puce avec Avatar",
   "widgets.chipWithTextAvatar": "Chip avec l'avatar de texte",
   "widgets.chipWithIconAvatar": "Puce avec icône Avatar",
   "widgets.customClickableChip": "Puce cliquable personnalisée",
   "widgets.outlineChip": "Puce de contour",
   "widgets.disableChip": "Désactiver la puce",
   "widgets.alertDialog": "Dialogue d'alerte",
   "widgets.animatedSlideDialogs": "Dialogues de diapositives animées",
   "widgets.fullScreenDialogs": "Dialogue plein écran",
   "widgets.formDialogs": "Form Dialogues",
   "widgets.simpleDialogs": "Dialogues simples",
   "widgets.responsiveFullScreen": "Plein écran réactif",
   "widgets.primary": "Primaire",
   "widgets.social": "Social",
   "widgets.user": "Utilisateur",
   "widgets.admin": "Admin",
   "widgets.permanentdrawer": "Tiroir permanent",
   "widgets.persistentdrawer": "Tiroir persistant",
   "widgets.swiches": "Swiches",
   "widgets.horizontalLinearAlternativeLabel": "Étiquette alternative linéaire horizontale",
   "widgets.horizontalNonLinearAlternativeLabel": "Étiquette alternative non linéaire horizontale",
   "widgets.notifications": "Notifications",
   "widgets.basicAlert": "Alerte de base",
   "button.acceptTerms": "Accepter les termes",
   "button.reject": "Rejeter",
   "button.addNew": "Ajouter un nouveau",
   "button.goToCampaign": "Aller à la campagne",
   "button.viewProfile": "Voir le profil",
   "button.sendMessage": "Envoyer un message",
   "button.saveNow": "Enregistrer maintenant",
   "button.pen": "Stylo",
   "button.search": "Rechercher",
   "button.downloadPdfReport": "Télécharger le rapport Pdf",
   "button.primary": "Primaire",
   "bouton.secondaire": "Secondaire",
   "bouton.danger": "Danger",
   "button.info": "Info",
   "button.success": "Succès",
   "button.warning": "Avertissement",
   "button.link": "Lien",
   "button.smallButton": "Petit bouton",
   "button.largeButton": "Grand bouton",
   "button.blockLevelButton": "Bouton de niveau de bloc",
   "button.primaryButton": "Bouton principal",
   "button.button": "Bouton",
   "button.save": "Enregistrer",
   "button.openMenu": "Ouvrir le menu",
   "button.openWithFadeTransition": "Ouvrir avec une transition de fondu",
   "button.openPopover": "Ouvrir Popover",
   "button.accept": "Accepter",
   "button.click": "Cliquer",
   "button.complete": "Achevée",
   "button.submit": "Soumettre",
   "button.back": "Arrière",
   "button.next": "Suivant",
   "button.previous": "Precedent",
   "button.completeStep": "Étape complète",
   "button.error": "Erreur",
   "button.writeNewMessage": "Ecrire un nouveau message",
   "button.saveChanges": "Sauvegarder les modifications",
   "button.addNewUser": "Ajouter un nouvel utilisateur",
   "button.more": "Plus",
   "hint.searchMailList": "Rechercher une liste de courrier",
   "widgets.AcceptorrRejectWithin": "Accepter ou rejeter dans",
   "widgets.quoteOfTheDay": "Citation du jour",
   "widgets.updated10Minago": "Mis à jour il y a 10 minutes",
   "widgets.personalSchedule": "Horaire personnel",
   "widgets.activeUsers": "Utilisateurs actifs",
   "widgets.totalRequest": "Demande totale",
   "widgets.new": "Nouveau",
   "widgets.ShareWithFriends": "Partager avec des amis!",
   "widgets.helpToShareText": "Aidez-nous à propager le monde en partageant notre site avec vos amis et adeptes sur les réseaux sociaux!",
   "widgets.thisWeek": "Cette semaine",
   "widgets.howWouldYouRateUs": "Comment nous évalueriez-vous?",
   "widgets.booking": "Réservation",
   "widgets.confirmed": "Confirmé",
   "widgets.monthly": "Mensuel",
   "widgets.weekly": "hebdomadaire",
   "widgets.target": "Target",
   "widgets.totalActiveUsers": "Total des utilisateurs actifs",
   "sidebar.user": "Utilisateur",
   "sidebar.miscellaneous": "Divers",
   "sidebar.promo": "Promo",
   "themeOptions.themeColor": "Couleur du thème",
   "module.inbox": "Boîte de réception",
   "module.drafts": "brouillons",
   "module.sent": "Envoyé",
   "module.trash": "Corbeille",
   "module.spam": "Spam",
   "module.frontend": "L'extrémité avant",
   "module.backend": "Backend",
   "module.api": "Api",
   "module.issue": "Problème",
   "components.emailPrefrences": "Préférences de messagerie",
   "components.myProfile": "Mon profil",
   "sidebar.gettingStarted": "Commencer",
   "widgets.deadline": "Date limite",
   "widgets.team": "Équipe",
   "widgets.projectManagement": "Gestion de projet",
   "widgets.latestPost": "Dernier message",
   "widgets.projectTaskManagement": "Gestion des tâches de projet",
   "widgets.selectProject": "Sélectionner un projet",
   "widgets.activityBoard": "Conseil d'activité",
   "widgets.checklist": "Liste de contrôle",
   "sidebar.shop": "Boutique",
   "sidebar.cart": "Chariot",
   "sidebar.checkout": "Check-out",
   "components.product": "Produit",
   "components.quantity": "Quantité",
   "components.totalPrice": "Prix total",
   "components.removeProduct": "Supprimer le produit",
   "components.mobileNumber": "Numéro de portable",
   "components.address2Optional": "Adresse 2 (facultatif)",
   "components.country": "Pays",
   "components.zip": "Zip *: français",
   "components.saveContinue": "Enregistrer continuer",
   "components.placeOrder": "Passer la commande",
   "components.payment": "Paiement",
   "components.billingAddress": "Informations client",
   "components.shippingDetails": "Livraison à",
   "components.ShippingAddressText": "L'adresse de facture ci-dessus",
   "components.CartEmptyText": "Votre panier est vide!",
   "components.NoItemFound": "Aucun article trouvé",
   "components.goToShop": "Aller à la boutique",
   "components.cardNumber": "Numéro de carte",
   "components.expiryDate": "Date d'expiration",
   "components.cvv": "CVV",
   "components.nameOnCard": "Nom sur carte",
   "components.confirmPayment": "Confirmer le paiement",
   "sidebar.saas": "SAAS",
   "sidebar.multiLevel": "MultiLevel",
   "sidebar.level1": "Niveau 1",
   "sidebar.level2": "Niveau 2",
   "sidebar.boxed": "En boîte",
   "sidebar.news": "Nouvelles",
   "sidebar.extensions": "Les extensions",
   "sidebar.imageCropper": "Cropper d'image",
   "sidebar.videoPlayer": "Lecteur vidéo",
   "sidebar.dropzone": "Zone de largage",
   "widgets.baseConfig": "Base Config",
   "widgets.customControlBar": "Barre de contrôle personnalisée",
   "widgets.withDownloadButton": "Avec bouton de téléchargement",
   "widgets.httpLiveStreaming": "HTTP Live Streaming",
   "widgets.keyboardShortcuts": "Raccourcis clavier",
   "button.useDefaultImage": "Utiliser l'image par défaut",
   "button.cropImage": "Crop Image",
   "widgets.preview": "Aperçu",
   "widgets.croppedImage": "Image recadrée",
   "sidebar.crm": "CRM",
   "widgets.transactionList": "liste de transaction",
   "widgets.transferReport": "rapport de transfert",
   "widgets.expenseCategory": "catégorie de dépense",
   "widgets.upcomingEvents": "évènements à venir",
   "widgets.OngoingProjects": "projets en cours",
   "widgets.ProjectStatus": "l'état du projet",
   "widgets.LiveChatSupport": "support de chat en direct",
   "sidebar.projects": "projets",
   "sidebar.projectDetail": "Détail du projet",
   "sidebar.clients": "les clients",
   "sidebar.reports": "rapports",
   "list.noItemToDisplay": "Aucun element à afficher",
   "list.noThingToDisplay": "Aucun {thing} à afficher",
   "list.sortBy": "Classé par",
   "general.none": "Aucun",
   "general.typeof": "{thing} Type",
   "general.parentProfile": "Profile parent",
   "catalogue": "Catalogue",
   "catalog.type": "Type de catalogue",
   "catalog.create.text": "Créer un catalogue",
   "catalog.create.successText": "Catalogue créé avec succès",
   "catalog.found": "{count} catalogue(s) trouvé(s)",
   "activeCatalog.alert.title": "Confirmation d'activation",
   "activeCatalog.alert.text": "Voulez-vous vraiment activer ce catalogue? Cela désactivera les autres",
   "activeCatalog.alert.successText": "Catalogue activé avec succès",

   "request.error.401": "Votre connection a expiré. Veuillez vous reconnecter",
   "request.error.403": "Vous n'avez pas les droits pour effectuer cette action",
   "request.error.404": "Non trouvé. Veuillez ressayer plus tard",
   "request.error.500": "Une erreur est survenue. Veuillez ressayer plus tard",
   "request.error.unknown": "Impossible d'effectuer toutes requetes. Veuillez verifier votre connexion internet et ressayer. Si le problème persiste veuillez contacter le support.",
   "request.error.auth.failed": "Bad login and/or password",
   "request.error.auth.login.failed": "Email et/ou mot de passe incorrect",
   "request.error.auth.blockedUser": "Sorry, you are not allowed to log into this association",
   "request.error.auth.notAllowed": "Sorry, you are not allowed to log into this association",
   "request.error.plan.nameAssigned": "The plan already exists",
   "request.error.plan.permissionParsingError": "Error while parsing permission, some fields are missing",
   "request.error.plan.featureParsingError": "Error while parsing feature, some fields are missing",
   "request.error.plan.notFound": "The plan was not found",
   "request.error.plan.maxActivatedPlanReached": "You have already reached the limit of three plans activated",
   "request.error.discount.notFound": "The discount was not found",
   "request.error.user.loginNotUnique": "This login is already assigned",
   "request.error.user.notConnected": "User not connected",
   "request.error.sampleCommunity.subDomainNotUnique": "This sub domain is already taken. Please choose another one",

   "language.english": "Anglais",
   "language.french": "Français",

   //   home page
   "home.browser.categorie": "Parcourir les catégories",
   "sign_in_out": "Se connecter / S'inscrire",
   "home.logout": "Déconnecter",
   "widgets.contact": "Contact",
   "home": "Accueil",
   "home.up_to_30": "Jusqu'à 30% de réduction",
   "home.clearance": "Clearance",
   "home.mobile.toggle": "Basculer le menu mobile",
   "component.cart.empty":"Aucun produit dans le panier.",
   "component.cart.total":"Total",
   "components.cart.view": "Voir le panier",
   "components.reviews": "Critiques",
   "components.cart.checkout": "Acheter",
   "errorMessageComponent.text": " Une erreur s'est produite lors du chargement des données. ",
   "errorMessageComponent.test": " Veuillez réessayer ",
    "errorMessageComponent1.text": " Quelque chose s'est mal passé ",
    "errorMessageComponent1.test": " Nous sommes désolés, cette demande n'est pas disponible.",
    "special.featured": "En vedette",
    "special.on_sale": "En vente",
    "special.top_rated": "Les mieux notés",
    "special.shop_now": "Acheter maintenant ",
    "special.the_best_choice": "Le meilleur choix",
    "special.top_rurchased": "Top acheté",
    "deal.receive1": "et recevoir ",
    "deal.receive2": "Coupon de 20 $.",
    "deal.receive3": "pour les premiers achats",
    "deal.last": "Les dernières offres",
    "category": "Catégorie",
    "showing": "Affichage de",
    "products": "Produits",
    "new": "Nouveau",
    "sale": "Vente",
    "only": "Seulement",
    "left": "gauche",
    "remove_cart": "Retirer le chariot",
    "update_cart": "Ajouter du panier",
    "add_to_cart": "Ajouter au panier",
    "quick_view": "Vue rapide",
    "free_shipping": "Expédition gratuite",
    "out_of_stock": "Rupture de stock",
    "deal.subscribe": "S'abonner à",
    "person_cart": "chariot personnel ",
    "it_in": "C'est dans",
    "sidebar.question": "Qui sommes-nous",
    "sidebar.vision": "Notre vision ",
    "sidebar.mission": "Notre mission",
    "sidebar.question_about`": "Vous avez des questions sur votre commande ? ",
    "sidebar.hesite": "N'hésitez pas à",
    "sidebar.contact": "contactez-nous",
    "sidebar.Contact": "Nous contacter",
    "sidebar.contact_question": "Vous avez des questions",
    "sidebar.contact_question1": "Utilisez le formulaire ci-dessous pour contacter l'équipe de vente.",
    "sidebar.contact_address": "Adresse",
    "sidebar.contact_info": "Informations de contact",
    "sidebar.contact_info1": "Nous aimerions construire quelque chose de grand ensemble. Merci beaucoup pour votre soutien !",
    "sidebar.keep": "Nous contacter",
    "sidebar.subject": "Sujet",
    "sidebar.problem": "restez en contact avec nous",
    "sidebar.custume": "Ce que les clients disent de nous",
    "sidebar.description1": "Buyamshop est une petite entreprise canadienne qui fournit des produits d'art africain faits à la main. Nous offrons des pièces d'art anciennes et décoratives faites à la main par les membres de la tribu à laquelle appartient l'objet. Vous trouverez d'authentiques chapeaux Juju, des tissus Kuba, des masques, des paniers africains, des boucliers africains en bois, des statues africaines en bois et en bronze, tous créés pour répondre à votre goût. L'Afrique, en particulier le Cameroun, le Congo et le Gabon, est notre principale source d'inspiration et c'est de là que proviennent tous nos articles. Nous travaillons en étroite collaboration avec des patriarches expérimentés, des artisans traditionnels talentueux et de jeunes artisans modernes talentueux. Nous sélectionnons avec soin des matériaux d'origine ethnique pour vous offrir ces créations magnifiques et originales. Nous choisissons de travailler avec les meilleurs artisans, en établissant une collaboration durable et équitable avec nos fournisseurs.",
    "sidebar.description2": "Pour la petite histoire, les chapeaux Juju, notre produit vedette, sont noués au sein du peuple Bamiléké de l'ouest du Cameroun. Ces coiffes en plumes sont fabriquées à la main et traditionnellement portées par les danseurs royaux lors des cérémonies tribales organisées par le chef car elles symbolisent la prospérité, la liberté et la beauté. Ces beaux objets d'héritage sont devenus très populaires dans la décoration intérieure et valorisent ainsi la culture Bamiléké tout en créant des opportunités pour les jeunes de cette communauté.",
    "sidebar.description3": "Il est très important de mentionner ici qu'aucun animal n'est tué ou maltraité pour obtenir ces plumes. Les artisans talentueux collectent les plumes dans les fermes industrielles. Quelle manière réfléchie de recycler ce matériau naturel.",
    "sidebar.description4": "Nous proposons des objets d'art anciens, modernes et décoratifs faits à la main par les membres de la tribu à laquelle appartient l'objet.",
    "sidebar.description5": "Vous trouverez ici d'authentiques chapeaux Juju, des tissus Kuba, des masques, des boucliers africains en bois, des statues africaines en bois et en bronze, tous créés pour répondre à vos goûts. L'Afrique, en particulier le Cameroun, le Congo et le Gabon, est notre principale source d'inspiration et c'est de là que proviennent tous nos articles. ",
    "sidebar.description6": "Nous sommes une entreprise familiale afro-canadienne située à Ajax, Ontario, Canada. Nous faisons de notre mieux pour vous apporter le meilleur des pièces d'art africain faites à la main. Nous fournissons des pièces d'art anciennes, modernes et décoratives. Tous nos articles sont faits à la main avec amour et délicatesse. Merci beaucoup pour votre soutien !",
    "sidebar.description7": "Pour la petite histoire, les chapeaux Juju, notre produit phare, sont noués au sein du peuple Bamiléké de l'ouest du Cameroun. Ces coiffes en plumes sont fabriquées à la main et traditionnellement portées par les danseurs royaux lors des cérémonies tribales organisées par le chef car elles symbolisent la prospérité, la liberté et la beauté. Ces beaux articles d'héritage sont devenus très populaires dans la décoration intérieure et valorisent ainsi la culture Bamiléké tout en créant des opportunités pour les jeunes de cette communauté.",
    "sidebar.description8": "Il est très important de mentionner ici qu'aucun animal n'est tué ou maltraité pour obtenir ces plumes. Les artisans talentueux collectent les plumes dans les fermes industrielles. Quelle manière réfléchie de recycler ce matériau naturel.",
    "sidebar.description9": "Nous vous remercions de soutenir notre petite entreprise.",
    "sidebar.footer_description": "BuyamShop est une boutique d'objets d'art africains faits à la main. Nous fournissons des pièces d'art anciennes, modernes et décoratives. Tous nos articles sont faits à la main avec amour et délicatesse. BuyamShop est une startup familiale située à Ajax, Ontario, Canada. Merci beaucoup pour votre soutien !",
    "sidebar.footer_description1": "Contactez-nous à",
    "sidebar.footer_description2": "Mode de paiement",
    "sidebar.footer_description3": "Information",
    "sidebar.footer_description4": "À propos de BuyamShop",
    "sidebar.footer_description5": "Comment acheter",
    "sidebar.footer_description6": "FAQ",
    "sidebar.footer_description7": "Nous contacter",
    "sidebar.footer_description8": "Politiques de retour",
    "sidebar.footer_description9": "Service à la clientèle",
    "sidebar.footer_description10": "Méthodes de paiement",
    "sidebar.footer_description11": "Garantie de remboursement !",
    "sidebar.footer_description12": "Renvoie à",
    "sidebar.footer_description13": "non-responsabilité",
    "sidebar.footer_description14": "Modalités et conditions",
    "sidebar.footer_description15": "Politique de confidentialité",
    "sidebar.footer_description16": "Mon compte",
    "sidebar.footer_description17": "Ma liste de souhaits",
    "sidebar.footer_description18": "Suivre ma commande",
    "sidebar.footer_description19": "Aide",
    "sidebar.footer_description20": "Copyright © ",
    "sidebar.footer_description21": "Boutique BuyamShop. Tous droits réservés. ",
    "sidebar.footer_description22": "Conditions d'utilisation ",
    "sidebar.footer_description23": "Politique de confidentialité",
    "sidebar.footer_description24": "Médias sociaux",
    "sidebar.footer_description25": "Disclaimer",
    "colors": "couleurs",
    "Sizes": "Tailles",
    "special_collection": "Collection spéciale déjà disponible.",
    "read_more": "Lire la suite ...",
    "sidebar.adress": " Adresse email ",
    "sidebar.purchase": "Acheter anonymement",
    "transaction.failed": "Votre transaction n'a pas pû être effectuée a cause d'un problème technique. Veuillez réessayer plutard.",
    "auth.log_in": "SE CONNECTER",
    "auth.agree": "Je suis d'accord avec la ",
    "auth.private": "politique de confidentialité",
    "form.firstName": "Prénoms",
    "form.lastName": "Noms",
    "form.region": "Région",
    "form.regionOrState": "Province / Région / Etat",
    "form.city": "Ville",
    "form.address": "Adresse",
    "form.postalCode": "Code postal / ZIP",
    "form.country": "Pays",
    "form.email": "Adresse Email",
    "form.streetAddress": "Adresse (inclure le numéro d'unité ou appt si applicable)",
    "form.createAccount": "Créer un compte ?",
    "form.yourOrder": "Votre commande",
    "form.orderNote": "Note de commande (optionelle)",
    "form.phoneNumber": "Numéro de téléphone",
    "cart.title": "BuyamShop | Panier d'achat",
    "cart.empty": "Aucun produit dans le panier",
    "cart.clear": "Vider le panier",
    "cart.total": "Total du panier",
    "cart.subtotal": "Sous-total",
    "cart.subtitle1": "boutique/liste",
    "cart.subtitle": "Boutique",
    "cart.coupon": "Coupon :",
    "cart.payment": "Mode de paiement",
    "cart.checkout": "Acheter",
    "cart.checkout.shipping": "Livraison",
    "cart.continue": "Continuer les achats",
    "cart.checkout.payment": "Procéder au paiement",
    "sidebar.filter": "Filtres:",
    "sidebar.clearAll": "Effacer tout",
    "sidebar.sort": "Trier par:",

   "accounts.plural": "Mon compte",
   "home.wishlist": "Souhaits",
   "sidebar.marketplace": "Produits"
};
