import BaseEnum from './BaseEnum';

export default class FeatureType extends BaseEnum {
    static COLOR = 'COLOR';
    static DEPTH = 'DEPTH';
    static DIAMETER = 'DIAMETER';
    static HEIGHT = 'HEIGHT';
    static MATERIAL = 'MATERIAL';
    static SIZE = 'DIMENSION';
    static WEIGHT = 'WEIGHT';
    static WIDTH = 'WIDTH';
    static FINISH = 'FINISH';
}
